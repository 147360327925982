import React from 'react';
import './App.css';
import {useState} from 'react';
import { Route, Routes, Link } from "react-router-dom"
import MainPage from "./MainPage";
import Wishlist from "./Wishlist";
import LampaFeed from "./LampaFeed/LampaFeed";
import Lvchk from "./Lvchk/Lvchk";

function App() {
    const [activeIndex, setActiveIndex] = useState(0);
  return (

      <div className="App">
          <div className="StickyTop">
              <a id="topLink" href="https://swathingsoap.com/"><img className="Logo-holder"
                                                             src="https://i.imgur.com/G5wQfJW.png" alt="logo"/></a>
              <hr className="Hr"></hr>
              {/*<Wishlist/>*/}
          </div>

              <Routes>
                  <Route path="/" element={<MainPage/>} />
                  <Route path="/wishlist" element={<Wishlist/>}/>
                  <Route path="/lampafeed" element={<LampaFeed/>}/>
                  <Route path="/lvchk" element={<Lvchk/>}/>
              </Routes>





      </div>
  );
}


export function LoadingImage(){
    return(
        <div>
            <img className="Rot-img" src="https://i.imgur.com/G5wQfJW.png"
                 alt="logo"/>
        </div>
    )
}


export default App;
